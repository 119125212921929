@use '../common.scss' as *;


.button {
  background-color: #7B61FF;
}


.showcase {
  color: #2e2e2e;
  background: #ffffff;
}

.logoWrapper {
  background-color: #ffffff;
  background-image: linear-gradient(89.55deg, rgba(255, 222, 242, 0.6) 13.51%, rgba(255, 222, 242, 0.235625) 21.4%, rgba(255, 222, 242, 0) 72.68%);
}

.headerWrapper {
  background-image: url('./images/headerBackground.svg'), linear-gradient(180deg, #fff 80%, #f4f4f4);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
}

.header {
  padding: 1.5em 1.5em;

  @media (min-width: $nonMobileMinWidth) {
    padding: 4.5em 3.75em 8em;
  }
}

.headline {
  font-weight: 700;
}

.headline, .advantagesList {
  position: relative;
  z-index: 5;
}

.advantage {
  &:before {
    background: url('./images/advantageBullet.svg') center center no-repeat;
  }
}

.bannerImage {
  background-image: url('./images/headerDecor.png');
}

.banksListWrapper {
  background: #f4f4f4;
  position: relative;

  &:after {
    background: url('./images/listBottom.svg') no-repeat;
    position: absolute;
    content: '';
    display: block;
    width: 3500px;
    height: 232px;
    bottom: -232px;
    left: 50%;
    margin-left: -1750px;
  }
}

.bank {
  border-color: #D9D9D9;
  background-color: #FFFFFF;
  color: #000000;

  .button {
    color: #545454;
    background-color: #E1FFDE;
    box-shadow: 0px 13px 5px rgba(33, 33, 33, 0.01), 0px 7px 4px rgba(33, 33, 33, 0.05), 0px 3px 3px rgba(33, 33, 33, 0.09), 0px 1px 2px rgba(33, 33, 33, 0.1), 0px 0px 0px rgba(33, 33, 33, 0.1);
  }
}

.recommendedBank {
  border-color: #F9A0D6;

  .button {
    background-color: #F9A0D6;
    color: #ffffff;
    background-image: radial-gradient(326.85% 2597.93% at 11.97% 83.75%, #F95BBB 0%, #F9A0D6 72.73%, #FFC3E7 100%);
    box-shadow: 0px 13px 5px rgba(33, 33, 33, 0.01), 0px 7px 4px rgba(33, 33, 33, 0.05), 0px 3px 3px rgba(33, 33, 33, 0.09), 0px 1px 2px rgba(33, 33, 33, 0.1), 0px 0px 0px rgba(33, 33, 33, 0.1);
  }
}

.bankRecommendedLabel {
  background: radial-gradient(144.15% 69.89% at 179.26% -40.86%, rgba(249, 160, 214, 0.6) 0%, rgba(249, 117, 197, 0.6) 47.76%, rgba(249, 76, 181, 0.6) 99.48%);
}

.creditorInfos {
  position: relative;
  z-index: 1;
  padding-top: 4em;
}

.disclaimer {
  padding: 2.5em 2.5em 1em;
  margin: 0 auto;
  background: #ffffff;
}


.documentation {
  a {
    color: #78A6FF;
  }
}
