@use '../common.scss' as *;


.button {
  background-color: #FFBD4A;
  color: #2e2e2e;
}

.logoWrapper {
  background-image: linear-gradient(90deg, rgba(190, 175, 253, 0.6) 13.51%, rgba(255, 255, 255, 0.235625) 32.01%, rgba(255, 255, 255, 0) 82.68%);
  position: absolute;
  backdrop-filter: blur(50px);
  z-index: 5;
}

.headerWrapper {
  background-position: 65vw bottom;
  padding-top: 72px;
}

.header {
  padding-bottom: 4.5em;

  &:after {
    background: url('./images/headerBackground.svg') no-repeat;
    content: "";
    position: absolute;
    bottom: -9em;
    right: -54%;
    display: block;
    width: 1100px;
    height: 750px;
    background-size: contain;
    z-index: 1;

    @media (max-width: $headerDecorMinWidth) {
      bottom: -20%;
      left: 60%;
    }

    @media (max-width: $nonMobileMinWidth) {
      bottom: 20%;
      left: 40%;
    }
  }
}

.headline {
  font-weight: 700;
}

.headline, .advantagesList {
  position: relative;
  z-index: 5;
}

.headerInnerWrapper {
  z-index: 5;
}

.advantage {
  &:before {
    background: url('./images/advantageBullet.svg') center center no-repeat;
  }
}

.banner {
  z-index: 5;

  .bannerImage {
    background-image: url('./images/headerDecor.png');
  }
}

.banksListWrapper {
  background: #f4f4f4;
  background-position: top center;
  background-size: 100% auto;
  padding: 6em 0 1em;
  margin: 0 0 11em;
  position: relative;

  @media (max-width: $nonMobileMinWidth) {
    padding-top: 1em;
  }

  &:before {
    background: url('./images/listTop.svg') no-repeat;
    content: '';
    position: absolute;
    display: block;
    top: -448px;
    left: 50%;
    margin-left: -1750px;
    width: 3502px;
    height: 474px;
  }

  &:after {
    background: url('./images/listBottom.svg') no-repeat;
    content: '';
    width: 3500px;
    height: 350px;
    position: absolute;
    display: block;
    bottom: -350px;
    left: 50%;
    margin-left: -1750px;
  }
}

.bank {
  border-color: #D9D9D9;
}

.recommendedBank {
  border-color: #7B61FF;

  .button {
    background-color: #7B61FF;
    color: #ffffff;
  }
}

.bankRecommendedLabel {
  background-color: #7B61FF;
}

.creditorInfos {
  position: relative;
  z-index: 1;
  margin-top: -6em;
}

.cookieWarning {
  background: linear-gradient(278.26deg, #FFBD4A 5.02%, #FFB44F 50.69%, #FF7B72 134.73%);

  .button {
    background: #7B61FF;
    color: #ffffff;
  }
}
