$maxWidth: 90em;

@mixin grid-columns($gap, $column-count, $min-width) {
  $gap-count: $column-count - 1;
  $total-gap: $gap-count * $gap;
  $max-width: calc((100% - $total-gap) / $column-count);

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(max($min-width, $max-width), 1fr));
  grid-column-gap: $gap;
}

$headerDecorMinWidth: 1024px;
$nonMobileTableMinWidth: 1200px;
$nonMobileMinWidth: 544px;
