@use '../common.scss' as *;

.showcase {
  background-color: rgba(12, 0, 71, 1.01);
}

.button {
  background: #9C88FF;
  box-shadow: 0px 10px 4px rgba(12, 0, 71, 0.01), 0px 6px 3px rgba(12, 0, 71, 0.05), 0px 3px 3px rgba(12, 0, 71, 0.09), 0px 1px 1px rgba(12, 0, 71, 0.1), 0px 0px 0px rgba(12, 0, 71, 0.1);
  border-radius: 10px;
}


.logoWrapper {
  background-color: #000000;
  background-image: linear-gradient(90deg, rgba(78, 78, 78, 0.6) 18.51%, rgba(78, 78, 78, 0.235625) 40.4%, rgb(0 0 0) 72.68%);
}

.logo {
  padding: 0.75em 1.5em;

  @media (min-width: $nonMobileMinWidth) {
    padding: 1em 5.875em;
  }
}

.headerWrapper {
  margin: 0 0 3.25em;
  padding: 1.5em 0 0;
  background-image: url('./images/headerWrapper.svg'),
    linear-gradient(#000, #2F292F 75%, rgba(0,0,0,0));
  background-size: 100% auto;
  padding-bottom: 220px;
  margin-bottom: -180px;
  background-repeat: no-repeat;
  background-position: bottom;

  @media (min-width: $nonMobileMinWidth) {
    padding: 2.75em 2.5em 180px;
  }
}

.header {
  max-width: $maxWidth - 5em;
  position: relative;
  color: #FFFFFF;

  @media (min-width: $nonMobileMinWidth) {
    border-radius: 1.5em;
  }
}

.headline {
  font-weight: 700;
  z-index: 5;
  position: relative;
}

.advantage {
  &:before {
    background: url('./images/advantageBullet.svg') center center no-repeat;
  }
}

.bannerImage {
  background-image: url('./images/headerDecor.png');
}

.bankPropValuePrimary {
  color: #9C88FF;
}

.recommendedBank {
  .button {
    background-color: #7B61FF;
  }
}

.bankRecommendedLabel {
  background: radial-gradient(75.89% 106.79% at 28.62% 39.38%, rgb(123, 97, 255) 0%, rgb(185, 152, 255) 100%);
}

.creditorInfos,
.disclaimer {
  color: #fff;
}

.cookieWarning {
  background: rgba(255,255,255,0.3);
  color: #F0F0F0;
}