@use './common.scss' as *;

body {
  overflow-x: hidden;
}

.button {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  border: 0;
  padding: 12px 21px;
  text-align: center;
  font-family: inherit;
  font-style: normal;
  font-weight: 500;
  border-radius: 10px;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.32px;
  cursor: pointer;
  text-decoration: none;
  background: #666666;
  color: #FFFFFF;
  box-shadow: 0px 13px 5px rgba(33, 33, 33, 0.01), 0px 7px 4px rgba(33, 33, 33, 0.05), 0px 3px 3px rgba(33, 33, 33, 0.09), 0px 1px 2px rgba(33, 33, 33, 0.1), 0px 0px 0px rgba(33, 33, 33, 0.1);

  &:hover {
    text-decoration: none;
  }
}


.showcase {
  width: 100%;
  color: #000000;
  background: #FFFFFF;
  padding-bottom: 0.05px;
}


.logoWrapper {
  box-sizing: border-box;
  width: 100%;
}

.logo {
  box-sizing: border-box;
  width: 100%;
  max-width: $maxWidth;
  margin: 0 auto;
  padding: 0.75em 1.5em;

  @media (min-width: $nonMobileMinWidth) {
    padding: 1em 2.5em;
  }
}

.logoImage {
  max-width: 240px;
  max-height: 44px;
}


.headerWrapper {
  box-sizing: border-box;
  width: 100%;
}

.header {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  max-width: $maxWidth;
  margin: 0 auto;
  padding: 1.5em 1.5em;

  @media (min-width: $nonMobileMinWidth) {
    padding: 4.5em 1em;
  }
}

.headerInnerWrapper {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.headline {
  font-weight: 500;
  font-size: 1.5em;
  line-height: 1.2;
  margin: 0 0 0.667em;
  padding-left: 8px;


  @media (min-width: $nonMobileMinWidth) {
    font-size: 5vw;
    padding-left: 0px;
  }

  @media (min-width: $headerDecorMinWidth) {
    font-size: 4em;
  }
}

.advantagesList {
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: 1.2;
  font-size: 0.875em;

  @media (min-width: $headerDecorMinWidth) {
    font-size: 1.15em;
  }
}

@media (min-width: $nonMobileMinWidth) {
  .headline,
  .advantagesList {
    margin-right: 30%;
    padding-right: 1.5rem;
  }
}

.advantage {
  min-height: 2.5em;
  padding-left: 2.5em;
  word-break: break-word;

  @media (min-width: $nonMobileMinWidth) {
    padding-left: 2.5em;
  }

  position: relative;
  display: flex;
  align-items: center;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 2.5em;
    height: 2.5em;
    margin-top: -1.25em;
  }

  & + & {
    margin-top: 0.9em;
  }
}

.banner {
  display: none;
  position: static;

  .bannerImage {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-position: center right;
    background-repeat: no-repeat;
    background-size: contain;
  }

  @media (max-width: $headerDecorMinWidth) {
    display: block;
    position: absolute;
    height: 250px;
    width: 30%;
    top: 0;
    right: 0;
  }

  @media (max-width: $nonMobileMinWidth) {
    position: relative;
    width: 100%;
    margin-bottom: 1em;

    .bannerImage {
      background-position: center center;
    }
  }

  @media (min-width: $headerDecorMinWidth) {
    display: block;
    right: 20%;
    top: 50%;
    width: 40%;
    height: 100%;
    height: calc(100% + 4em);
    max-width: 500px;
    max-height: 448px;
    transform: translate(50%, -50%);
    position: absolute;
  }
}

.banksListWrapper { }

.banksList {
  box-sizing: border-box;
  width: 100%;
  max-width: $maxWidth;
  margin: 0 auto;
  padding: 0;

  @media (min-width: $nonMobileMinWidth) {
    padding: 0 2.5em;
  }

  display: grid;
  grid-column-gap: 1em;
  grid-row-gap: 1.5em;
}

.bankListCard {
  @include grid-columns(1em, 4, 20em);
  grid-row-gap: 3.5em;

  @media (max-width: $nonMobileMinWidth) {
    grid-row-gap: 1.5em;
  }
}

.bankListTable {
  grid-row-gap: 1.5em;
}

.bankWrapper {
  --rounding: 20px;
  --logo-size: 92px;
  --border: 4px;
  --text-padding: 1em;
  --back-color: #d1d1d1;

  border-radius: var(--rounding);
  box-sizing: border-box;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.09);
  position: relative;
  z-index: 10;

  @media (min-width: $nonMobileMinWidth) {
    border-radius: 1.25em;
  }
}

.recommendedBankWrapper {
  @media (max-width: $nonMobileMinWidth) {
    margin-top: 3em;
  }
}

.bank {
  height: 100%;
  box-sizing: border-box;
  background: #fff;
  border-radius: 20px;
  z-index: 5;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.recommendedBankWrapper{

  .bank {
    border-radius: calc(var(--rounding) + var(--border));
    padding: var(--border);
    position: relative;
    background: var(--back-color);
    z-index: 5;
  }

  .recommendedBankLogoImageWrapper {
    position: absolute;
    z-index: 10;
    left: 0;
    top: 0;
    width: calc(var(--logo-size) + var(--border) * 2);
    height: calc(var(--logo-size) + var(--border) * 2);
    background: var(--back-color);
    border: var(--border) solid var(--back-color);
    border-radius: var(--rounding);
    padding: 0.5em;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;

    &:after {
      content: '';
      position: absolute;
      display: block;
      top: 4px;
      left: 4px;
      right: 4px;
      bottom: 4px;
      border-radius: var(--rounding);
      background: rgba(255,255,255,0.15);
    }
  }

  .bankName {
    position: relative;
    margin-left: calc(var(--logo-size) + var(--border));
    box-sizing: border-box;
    height: calc(var(--logo-size) + var(--border));
    background: white;
    padding: var(--text-padding);
    border-radius: var(--rounding) var(--rounding) 0 0;
    z-index: 5;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: calc(0px - var(--rounding) - var(--border));
      width: calc(var(--rounding) + var(--border));
      height: calc(var(--rounding) + var(--border));
      background: white;
    }
  }

  .bankLogo {
    padding: 0;
  }

  .bankPropsList {
    background: #ffffff;
    padding: var(--text-padding);
    border-radius: var(--rounding) 0 0 0;
    height: 100%;
    margin: 0;
  }

  .bankButtonsList {
    background: #ffffff;
    border-radius: 0 0 var(--rounding) var(--rounding);
    padding-bottom: calc(2.25em - var(--border));

    .bankOgrnNote {
      margin-bottom: -18px;
    }
  }

  .button {
    font-weight: 700;
  }

  .bankBackface {
    position: relative;
    z-index: 5;
  }
}

.bankRecommendedLabel {
  position: absolute;
  box-sizing: border-box;
  left: 0;
  top: -1em;
  margin-top: -0.125rem;
  transform: translate(0, -50%);
  padding: 1em 1.333em 2em;
  font-weight: 700;
  font-size: 0.75em;
  line-height: 2;
  text-align: center;
  color: #FFFFFF;
  background-color: #999999;
  border-radius: 1.667em 1.667em 0 0;
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 4px;

}

.bankName {
  display: flex;
  align-items: center;
}

.bankLogo {
  height: 5.75em;
  padding: 0 1em;
  justify-content: center;
  align-items: center;
  display: flex;
  grid-gap: 20px;
  color: #2E2E2E;
  font-weight: 700;
  line-height: 1.5em;

  @media (min-width: $nonMobileMinWidth) {
    padding: 0 1em;
  }

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 6em;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
}

.bankLogoImageWrapper {
  width: 64px;
  min-width: 64px;
  display: flex;
  align-content: center;
  justify-content: center;
}

.bankLogoImage {
  max-width: 100%;
  max-height: 100%;
  border: 0;
  display: none;
}

.bankIconImage {
  max-width: 100%;
  max-height: 100%;
  border: 0;
  border-radius: 16px;
}

.bankPropsList {
  display: grid;
  grid-gap: 24px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.22;

  margin: 1.333em 1em 1.333em;

  @media (min-width: $nonMobileMinWidth) {
    margin: 1.333em 1rem 1.333em;
  }

  &:empty {
    display: none;
  }
}

.bankProp {
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.bankPropName {
  margin-right: 0.5em;
}

.bankPropValue {
  margin-left: 0.5em;
  text-align: right;
  word-break: break-word;
}

.bankPropValuePrimary {}

.bankButtonsList {
  padding: 2.25em 1.5em 0.75em;
  text-align: center;

  .button {
    width: 100%;
  }

  .bankOgrnNote {
    color: rgba(26, 36, 64, 0.3);
    margin-top: 8px;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
  }
}


.bankListCard {
  @media (max-width: $nonMobileMinWidth) {
    padding: 0.5em;
  }

  .bank { }

  .bankRecommendedLabel { }

  .bankLogo { }

  .bankLogoImage {
    display: none;
  }

  .bankIconImage { }

  .bankPropsList { }

  .bankProp { }

  .bankPropName { }

  .bankPropValue { }

  .bankButtonsList { }

}


.bankListTable {
  .recommendedBankWrapper {
    margin-top: 44px;
  }

  @media (min-width: $nonMobileTableMinWidth) {
    .bank {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .bankLogo {
      background: #fff;
      width: 100%;
      flex-direction: column;
      text-align: center;
      border-radius: var(--rounding) 0 0 var(--rounding);
      grid-gap: 8px;
      height: 8em;
    }

    .bankLogoImageWrapper {
      width: 100%;
      max-width: 150px;
      max-height: 50px;
    }

    .recommendedBankWrapper .recommendedBankLogoImageWrapper {
      width: calc(270px - var(--rounding));
      height: calc(70px - var(--rounding));
      box-sizing: content-box;
    }

    .bankRecommendedLabel {
      min-width: 270px;
      width: max-content;
    }

    .bankLogoImage {
      display: block;
    }

    .bankIconImage {
      display: none;
    }

    .bankName {
      height: 100%;
      border-radius: var(--rounding) 0 0 var(--rounding);
      background: none;
      margin: 0;
      width: 270px;
      padding: 0;

      &:after {
        left: initial;
        right: 0;
        top: calc(70px - var(--rounding));
      }
    }

    .recommendedBankWrapper {
      .bankName {
        padding: 70px 0 0 0;
      }
    }

    .bankPropsList {
      font-size: 16px;
      line-height: 1.2;
      flex: 2 1;
      text-align: center;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      justify-items: center;
      box-sizing: border-box;
      border-radius: var(--rounding) 0 0 0;
    }

    .bankProp {
      margin: 0 0.5em;
      display: grid;
      justify-items: center;
      grid-gap: 40px;
    }

    .bankPropName {
      &:after {
        content: ':';
      }
    }

    .bankPropValue {
      text-align: center;
    }

    .bankButtonsList {
      height: 100%;
      box-sizing: border-box;
      border-radius: 0 var(--rounding) var(--rounding) 0;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      .bankOgrnNote {
        max-width: 320px;
        min-width: 240px;
        width: 260px;
      }
    }

    .recommendedBankWrapper {
      .bankButtonsList {
        padding-right: calc(1em - 4px);
        padding-bottom: 12px;
      }

      .bankLogo {
        padding: 0 1em;
      }
    }
  }
}


.creditorInfos {
  box-sizing: border-box;
  width: 100%;
  max-width: $maxWidth;
  margin: 0 auto;
  padding: 0 1.5em;

  @media (min-width: $nonMobileMinWidth) {
    padding: 0 2.5em;
  }
}

.creditorInfoHeader {
  position: relative;
  font-style: normal;
  font-weight: 700;
  font-size: 1.5em;
  line-height: 1.167;
  margin: 1.667em 0 2.5em;
  padding-right: 2em;
  cursor: pointer;
}

.creditorInfoHeaderExpander {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -0.75em;
  width: 1.5em;
  height: 1.5em;
  transition: transform ease .2s;
}

.creditorInfosList {
  @include grid-columns(1em, 4, 20em);
  grid-row-gap: 3em;
  word-break: break-word;
  display: none;
}

.creditorInfosExpanded {
  .creditorInfoHeaderExpander {
    transform: rotate(180deg);
  }
  .creditorInfosList {
    display: grid;
  }
}


.creditorInfo {}

.creditorHeader {
  font-size: 1.167em;
  margin: 0 0 0.5em;
}

.creditorProp {}

.creditorPropName {}

.creditorPropValue {}


.disclaimer,
.documentation {
  box-sizing: border-box;
  width: 100%;
  max-width: $maxWidth;
  margin: 2.5em auto 0;
  padding: 0 1.5em 1em;

  @media (min-width: $nonMobileMinWidth) {
    padding: 0 2.5em 1em;
  }
}

.documentation {
  margin-top: 0;

  a {
    color: #1c69ff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}


.cookieWarning {
  box-sizing: border-box;
  width: 100%;
  max-width: $maxWidth;
  margin: 1em auto;
  padding: 1.5em;
  text-align: left;

  background: #FFFFFF;
  color: #000000;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.09);
  border-radius: 20px;

  @media (min-width: $nonMobileMinWidth) {
    margin: 2em auto;
    padding: 2em;
  }
}

.cookieWarningButtons {
  margin: 1.5em 0 0;
  text-align: right;
}
