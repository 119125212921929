@use '../common.scss' as *;


.button {
  background-color: #E689A4;
  font-weight: 700;
}


.logoWrapper {
  background: transparent;
  color: #FFFFFF;
  position: absolute;
}

.logo {
  padding: 0.75em 1.5em;
  position: relative;

  @media (min-width: $nonMobileMinWidth) {
    padding: 1em 5.875em;
  }

  &:after {
    content: "";
    position: absolute;
    top: -18px;
    left: 72px;
    background-color: #ffffff;
    width: 106px;
    height: 106px;
    border-radius: 20px;
    z-index: 0;
    transform: rotate(40deg);
  }

  .logoImage {
    position: relative;
    z-index: 1;
  }
}

.headerWrapper {
  color: #000000;
  margin: 0 0 0em;
  padding: 0;

  @media (min-width: $nonMobileMinWidth) {
    padding: 0 2.5em 0;
  }

  &:before {
    background: url('./images/headerBackground.svg') no-repeat;
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -1752px;
    width: 3505px;
    height: 424px;
  }
}

.header {
  max-width: $maxWidth - 5em;
  padding: 1.5em 1.5em 1.5em;

  @media (min-width: $nonMobileMinWidth) {
    padding: 5.25em 3.375em 1.5em;
  }
}

.headline {
  font-weight: 700;
  position: relative;
  z-index: 5;
}

.advantage {
  &:before {
    background: url('./images/advantageBullet.svg') center center no-repeat;
  }
}

.banner {
  &:before {
    content: '';
    position: absolute;
    top: 40px;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 60px;
    background-color: #ffffff;
    transform: rotate(-65deg);
  }

  .bannerImage {
    background-image: url('./images/headerDecor.png');
  }
}

.banksListWrapper {
  background-color: #f4f4f4;
  margin-bottom: 127px;
  padding-top: 60px;

  @media (max-width: $nonMobileMinWidth) {
    padding-top: 1em;
  }
}

.banksList {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: 3501px;
    height: 127px;
    bottom: -126px;
    left: 50%;
    margin-left: -1750px;
    background: url('./images/listBottom.svg') no-repeat;
  }
}

.bank {
  border-color: #D9D9D9;
}

.recommendedBank {
  border-color: #95D367;

  .button {
    background-color: #95D367;
  }
}

.bankRecommendedLabel {
  background: radial-gradient(279.79% 135.65% at 2.66% 13.21%, #8CCA5D 0%, #83C054 85.68%, #4A831E 100%);
}

.cookieWarning {
  background: #F7FFD1;

  .button {
    background: #CD5E7E;
  }
}